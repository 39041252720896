.slider {
  position: relative;
  width: 97%;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}

.image {
  width: 100%;
  padding: 13px 0px 13px 0px;
}

.right-arrow {
  position: absolute;
  fill: white;
  top: 40%;
  right: -9px;
  font-size: 2.5rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  position: absolute;
  fill: white;
  top: 40%;
  left: -9px;
  font-size: 2.5rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}

@media screen and (min-height: 700px) {
  .image {
    padding: 12px 0px 12px 0px;
  }
}
