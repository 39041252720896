@import url("https://fonts.googleapis.com/css2?family=Caveat&display=swap");

.author-content {
  margin: 20px 20px 20px 0px;
  font-size: 18px;
  float: right;
}
.word-content {
  font-family: "Caveat", cursive;
  font-size: 25px;
}
