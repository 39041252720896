h1 {
  margin-top: 14px;
}

.wrapper {
  z-index: 999 !important;
  cursor: pointer;
}

.logo {
  filter: invert(81%) sepia(86%) saturate(0) hue-rotate(202deg) brightness(136%)
    contrast(169%) !important;
}

.highlight {
  color: #315496;
  font-weight: bold;
  font-size: 18px;
}

.main {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.main-content {
  background: #315496;
}
.box1 {
  border: 1px solid #315496;
  margin: 0;
}
.box2 {
  background-color: #dae1f3;
  padding: 5px;
}

.content-box {
  overflow-y: scroll;
  overflow-x: hidden;
}

.box3 {
  margin: 0;
  font-size: 36px;
  align-items: center;
  color: #315496;
  flex: 100%;
}
.box4 {
  padding: 16px;
  margin: 20px 21px 0px 21px;
  border: 1px solid #85b1ce;
  line-height: 24px;
  p {
    margin: 0;
  }
}
.box5 {
  padding: 10px;
  border: 1px solid #85b1ce;
  background-color: #dae1f3;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.box6 {
  padding: 20px 0px 0px 0px;
  border: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
}

.logout-img {
  width: auto;
  float: left;
  filter: invert(81%) sepia(86%) saturate(0) hue-rotate(202deg) brightness(136%)
    contrast(169%) !important;
}

.logout {
  font-size: 1em;
  position: relative;
  white-space: nowrap;
  right: -12px;
  bottom: -1px;
  overflow: hidden;
  color: white;
  opacity: 0;
  transition: opacity 0.45s;
  -webkit-transition: opacity 0.35s;
}

.button {
  text-decoration: none;
  float: right;
  padding: 12px;
  margin: 15px;
  width: 60px;
  transition: width 0.35s;
  -webkit-transition: width 0.35s;
  overflow: hidden;
  cursor: pointer;
}

.button:hover {
  width: 140px;
}

.button:hover .logout {
  opacity: 0.9;
}

.button {
  text-decoration: none;
}

.user-details {
  position: absolute;
  right: 10px;
}

.avatar {
  vertical-align: middle;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.cards {
  border-radius: 15px;
  box-shadow: 0px 1px 6px 6px #dae1f3;
}

.mt-header {
  width: 30%;
  margin: 10px 0px 10px 10px;
}

.marquee {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  span {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 14s linear infinite;
  }
  :hover {
    animation-play-state: paused;
  }
}

.marquee:hover {
  animation-play-state: paused;
}

@keyframes marquee {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

@media (max-width: 950px) {
  .cards {
    width: auto;
  }
  .sidebar-none {
    transition: transform 300ms ease-out;
  }
}

@media screen and (min-height: 700px) {
  .box4 {
    margin: 20px 21px 0px 21px;
  }
}

@media screen and (min-width: 1860px) {
  .box4 {
    margin: 20px 20px 0px 20px;
  }
}

@media (max-width: 850px) {
  .box5 {
    flex: 100%;
    flex-direction: column !important;
  }
  .user-info {
    display: flex;
    position: unset !important;
    width: 100%;
    justify-content: end;
  }
  .avatar {
    margin-left: 25px !important;
  }
  .main-content {
    flex-direction: column-reverse;
  }
}
