.App {
  display: flex;
  height: inherit !important;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar li {
  cursor: pointer;
  margin-bottom: 10px;
}

.toggle-button {
  position: fixed;
  top: 6px;
  left: 0px;
  cursor: pointer;
  z-index: 2;
  visibility: hidden;
}

@media (max-width: 950px) {
  .App {
    display: block;
  }
  .toggle-button {
    visibility: visible;
    position: absolute !important;
  }
}
