.sidebar {
  color: black;
  overflow-y: scroll;
  width: 260px;
  background-color: #e0eaff;
}

.side-header {
  color: #315496;
  cursor: pointer;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar li {
  position: relative;
  cursor: pointer;
  padding: 8px;
  transition: background-color 0.3s;
}

.sidebar li:hover {
  background-color: #777;
}

.MuiListItem-button:hover {
  background-color: #7699e3 !important;
}

.MuiListItem-root {
  padding: 5px 16px 0px 16px !important;
}

.submenu {
  position: absolute;
  top: 0;
  left: 10%;
  margin-top: 0px;
  display: none;
  background-color: red;
  list-style: none;
  padding: 8px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  width: max-content;
}

.sidebar li:hover .submenu {
  display: block;
}

.main-content h2 {
  color: #333;
}
.header1 {
  background: #315496 !important;
  color: white !important;
  pointer-events: none;
}

.subList {
  background-color: #bed0f5 !important;
}

.subListLight {
  background-color: #a4bef5 !important;
}

.subListDark {
  background-color: #86a7ed !important;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.outer-container {
  display: flex;
  flex-direction: column;
}

@media (max-width: 950px) {
  .sidebar {
    display: flex;
    width: auto;
    flex-direction: column;
    margin-top: 51px;
  }
  .side-header {
    position: fixed;
    z-index: 2;
    width: 100%;
    background: white;
    margin-top: 0px;
  }
}

@media (max-width: 950px) {
  .sidebar {
    max-width: none;
  }
}
